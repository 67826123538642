/*
::
:: Theme Name: Decision - Lawyer & Attorney HTML Template
:: Email: Nourramadan144@gmail.com
:: Author URI: https://themeforest.net/user/ar-coder
:: Author: ar-coder
:: Version: 1.0
:: 
*/

// :: Setup mouseenter On Case Study
$(document).ready(function () {
    // :: Loading
    $(window).on('load', function () {
        $('.loading').fadeOut();
    });

    // :: Height Header Section
    $('.header, .header .table-cell').height($(window).height() + $('header.navs').height() + 100);
    $('.header-2, .header-2 .table-cell').height($(window).height() + $('header.navs').height() + 40);

    // :: Add Class Active For ('.nav-bar')
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > $('header.navs').height() + 25) {
            $('header.navs, .nav-bar').addClass('active');
            $('.open-nav-bar span').addClass('scrolled')
        } else {
            $('header.navs, .nav-bar').removeClass('active');
            $('.open-nav-bar span').removeClass('scrolled')
        }
    });

    // :: Scroll Smooth To Go Section
    $('.move-section').on('click', function (e) {
        e.preventDefault();
        var anchorLink = $(this);
        $('html, body').stop().animate({
            scrollTop: $(anchorLink.attr('href')).offset().top + 1
        }, 1000);
    });

    // :: Add Class Active To Search Box
    $('.open-search-box').on('click', function () {
        $('.search-box').fadeIn();
    });
    $('.search-box, .close-search').on('click', function () {
        $('.search-box').fadeOut();
    });
    $('.search-box form').on('click', function (e) {
        e.stopPropagation();
    });

    // :: Open And Close Menu
    $('.open-menu').on('click', function () {
        $('.menu-box').fadeIn().addClass('active');
    });
    $('.menu-box').on('click', function () {
        $(this).fadeOut().removeClass('active');
    });
    $('.exit-menu-box').on('click', function () {
        $('.menu-box').fadeOut().removeClass('active');
    });
    $('.menu-box .inner-menu').on('click', function (e) {
        e.stopPropagation();
    });
});